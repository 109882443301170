import React from 'react';
import { string, func, bool, array } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useHistory } from 'react-router-dom';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, prepareAbbreviatedName } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, Avatar, Button, InlineTextButton, NamedLink, ResponsiveImage, ReviewRating } from '../../components';

import css from './ListingCard.module.css';
import { ASSOCIATED, getBreadcrumb, getCategoriesAndSubcategories, getCreatorUser, listingIsDraft } from '../../util/listingsHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { prepareMemberData } from '../../util/userHelpers';
import { generatePreSignedUrl } from '../../util/api';

const _ = require('lodash');

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="SearchListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const SearchListingCardComponent = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isIframe,
    showEdit,
    showDelete,
    onDelete,
    showActions,
  } = props;

  const [videoUrl, setVideoUrl] = React.useState(null);
  const [videoInProcess, setVideoInProcess] = React.useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, description, publicData = {} } = currentListing.attributes || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const categoryBreadcrumb = getBreadcrumb(config, listing);
  const { marketplaceName } = config;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'landscape-crop2x',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const { brand, isAssociated = false, creatorType } = publicData
  // const ratingData = [4, 3]; // Todo: Get data from source
  // const rating = ratingData.length ? ratingData.reduce((a, b) => a + b, 0) / ratingData.length : 0;
  // const ratingFloor = Math.floor(rating);
  // const ratingShow = (Math.round(rating * 10) / 10).toFixed(1).replace('.0', '');

  // const imageVariants = firstImage?.attributes?.variants || [];
  // const image = imageVariants['landscape-crop2x'] || imageVariants['landscape-crop'] || imageVariants['default'] || imageVariants['square-small2x'] || imageVariants['square-small'] || imageVariants['landscape-crop2x'] || imageVariants['landscape-crop'];

  const MAX_TITLE_LENGTH = 20;

  let listingProps = {
    name: 'ListingPage',
    params: { id, slug },
    ...(isIframe ? {to: { search: 'iframe'}} : {target: '_blank'}),
  };

  let editListingProps = {
    name: 'EditListingPage',
    params: { id, slug, type: 'edit', tab: 'details' }
  };

  let isDraft = listingIsDraft(currentListing);

  let reviews = [];

  let store = isAssociated ? props.stores.find(s => s.id.uuid == author.id.uuid) : null;
  let businessName;
  if (store) {
    businessName = store?.attributes?.profile?.publicData?.businessName;
    let businessOwnerName = store?.attributes?.profile?.publicData?.businessOwnerName;
    let displayName = store?.attributes?.profile?.displayName;
    let businessProfilePicture = store?.attributes?.profile?.publicData?.businessProfilePicture;
    store.attributes.profile.abbreviatedName = prepareAbbreviatedName(businessName || businessOwnerName || displayName || '');
    store.profileImage = businessProfilePicture || null;
    store.forcePicture = !!businessProfilePicture;

    reviews = store?.attributes?.reviews || [];
  }

  const authorUser = _.cloneDeep(author);
  const creatorUser = getCreatorUser(listing, props.creators);

  let { authorObj, authorId, authorName, rating, ratingCounter, authorType, reviews: memberReviews } = prepareMemberData(authorUser, creatorUser, creatorType, reviews, isAssociated);
  reviews = memberReviews.length ? memberReviews : reviews;

  let canShowTeam = props.showTeam && authorType !== 'own';

  const inlineListener = (event, name, params) => {
    event.preventDefault();
    event.stopPropagation();
    let uri = createResourceLocatorString(name, routeConfiguration, params, {});
    if (!isIframe && typeof window !== 'undefined') {
      window.open(uri, '_blank');
    } else {
      history.push(uri);
    }
  };

  let profilePage = 'ProfilePage';
  let userRouteParams = {
    id: authorId || author.id.uuid
  };

  if (authorType !== 'own') {
    let isMemberAssociated = authorType == ASSOCIATED
    profilePage = isMemberAssociated ? 'TeamPageMember' : 'TeamPage';
    userRouteParams = {
      id: store?.id?.uuid,
      type: isMemberAssociated ? 'member' : 'owner',
      ...(isMemberAssociated ? {memberId: authorId || author.id.uuid} : {})
    };
  }

  let teamData = (classStr) => (showAuthorInfo ? <div className={classStr}>
      <div className={css.authorName}>
        Sold by
        <InlineTextButton
          onClick={event => !isIframe ? inlineListener(event, profilePage, userRouteParams) : null}>
          { authorName }
        </InlineTextButton>
        {authorType !== 'own' ? `, Sales ${authorType == ASSOCIATED ? 'Associate' : 'Owner'}` : null}
        {canShowTeam ? ' at ' : null}
        {canShowTeam ? <InlineTextButton onClick={event => inlineListener(event, 'TeamPage', {id: store.id.uuid, type: 'detail'})}>{ businessName || marketplaceName }</InlineTextButton> : null}
      </div>
    </div> : null);

    const hasVideo = currentListing.attributes?.publicData?.videos;

    const videoId = hasVideo ? currentListing.attributes?.publicData?.videos[0] : null;

    React.useEffect(() => {
      if (hasVideo && videoId && !videoUrl && !videoInProcess) {
        setVideoInProcess(true);
        generatePreSignedUrl({ filename: videoId})
          .then(url => {
            setVideoUrl(url);
          })
          .catch(err => {
            console.error(err);
          });
      }
    }, [videoId, videoUrl, videoInProcess]);
  return (<div>
    <NamedLink className={classNames(classes, css.searchListingCardContainer, { [css.isDraft]: isDraft })} {...listingProps}>
      {isDraft ? <div className={css.draftOverlay}>
        <div>
          <FormattedMessage id="ManageListingCard.draftOverlayText" values={{listingTitle: title}}/>
        </div>
        <div>
          {showEdit ? <NamedLink {...editListingProps}>
            <Button>{intl.formatMessage({ id: 'ListingPage.finishListing' })}</Button>
          </NamedLink> : null}
        </div>
      </div> : null}
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {hasVideo && videoUrl ? (
          <video
            className={css.rootForImage}
            src={videoUrl.url}
            autoPlay={false}
            muted
            onMouseEnter={e => e.target.play()}
            onMouseLeave={e => {
              e.target.pause();
              e.target.currentTime = 0;
            }}
          />
        ) : <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />}
      </AspectRatioWrapper>
      <div className={css.mainSectionInfo}>
        <div className={css.listingMainInfo}>
          <div className={css.categoryTitle}>
            <div>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
              {/* {title.substring(0, MAX_TITLE_LENGTH)} */}
            </div>
            <div className={css.brand}>{brand || '\u00A0'}</div>
          </div>
        </div>
        {categoryBreadcrumb ? <div className={css.categoryLabel}>{categoryBreadcrumb}</div> : null}
        {teamData(css.authorAndReviewContainerMobile)}
        {showAuthorInfo ? <div className={css.listingCardAuthorContainer}>
          <div className={css.authorPicture}>
            <Avatar className={css.avatar} user={authorObj} disableProfileLink={true}/>
          </div>
          {teamData(css.authorAndReviewContainer)}
        </div> : null}
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
      </div>
    </NamedLink>
    {showActions ? <div className={classNames(css.categoryListingButtonContainer, {[css.categoryWithDelete]: showDelete})}>
      {showEdit ? <NamedLink {...editListingProps}>
        <Button>Edit</Button>
      </NamedLink> : null}
      {showDelete ? <Button onClick={onDelete}>Delete</Button> : null}
    </div> : null}
  </div>);
};

SearchListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  isIframe: false,
  showEdit: false,
  showDraft: false,
  showDelete: false,
  showActions: false,
  showTeam: true,
  stores: [],
  creators: [],
};

SearchListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  isIframe: bool,
  showEdit: bool,
  showDraft: bool,
  showDelete: bool,
  showActions: bool,
  showTeam: bool,
  stores: array,
  creators: array,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(SearchListingCardComponent);
